import React, { Component } from "react";

import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import PropTypes from "prop-types";


import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    container: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1, 0, 2, 0),
    },
    section1: {
        margin: theme.spacing(3, 2),
    },
    section2: {
        margin: theme.spacing(2),
    },
    section3: {
        margin: theme.spacing(3, 1, 1, 2),
        overflow: "hidden"
    },
    wrapperBtn: {
        float: "left",
        position: 'relative',
    },
    progressBtn: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});


class BoxCurrentPlan extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { user, activeSubscription, stripeRole, onClick } = this.props;
        const { classes } = this.props;

        if (user && activeSubscription) {
            return (
                <Grid sm={12} md={8} item>
                    <Box className={classes.container} boxShadow={1}>
                        <div className={classes.section1}>
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <Typography gutterBottom variant="h4">
                                        {`Current plan: ${stripeRole}`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6">
                                        {`${new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: activeSubscription.currency,
                                        }).format((activeSubscription.unit_amount / 100).toFixed(2))} per ${activeSubscription.interval
                                            }`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.section3}>
                            <div className={classes.wrapperBtn}>
                                <Button disabled={this.props.loadingBtn} variant="contained" color="primary" onClick={() => onClick()}>Manage your plan</Button>
                                {this.props.circBtn["currentPlan"] && <CircularProgress size={24} className={classes.progressBtn} />}
                            </div>
                        </div>
                    </Box>
                </Grid>);
        }

        return (<div></div>);
    }
}


BoxCurrentPlan.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(BoxCurrentPlan);

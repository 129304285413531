import React, { Component } from "react";

import { Button } from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import PropTypes from "prop-types";


import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    control: {
        padding: theme.spacing(2),
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1, 0, 2, 0),
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    section1: {
        margin: theme.spacing(3, 2),
    },
    section2: {
        margin: theme.spacing(2),
    },
    section3: {
        margin: theme.spacing(3, 1, 1, 2),
    },
});


class BoxProduct extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPrice: null
        };
    }


    selectPrice = (price) => {
        this.setState({ selectedPrice: price });
    }


    componentDidMount() {
        const { prices, currentSubscription } = this.props;

        this.selectPrice(currentSubscription && prices.some(x => x.id == currentSubscription.id) ? prices.find(x => x.id == currentSubscription.id) : prices[0]);
    }


    renderChip = (price) => {
        const { classes } = this.props;

        return <Chip key={price.id } clickable className={classes.chip} color={price.id == this.state.selectedPrice?.id ? "primary" : "default"} label={"Per " + price.interval} onClick={(p) => this.selectPrice(price) } />
    }

    renderButton = () => {
        const { currentSubscription } = this.props;

        if (currentSubscription && this.state.selectedPrice?.id == currentSubscription.id)
            return (
                <Button variant="contained" color="primary" disabled onClick={() => this.props.onClick()}>Current</Button>
            );
        else
            return (
                <Button disabled={this.props.loadingBtn} variant="contained" color="primary" onClick={() => this.props.onClick(this.state.selectedPrice?.id)}>Choose</Button>
            );
    }

    render() {
        const { user, product, prices, currentSubscription } = this.props;
        const { classes } = this.props;

        if (user && !currentSubscription) {
            const chips = [];

            prices.forEach(price => {
                chips.push(this.renderChip(price));
            });

            return (
                <Grid xs={12} sm={4} key={product.id} item>
                    <Box className={classes.container} boxShadow={1}>
                        <div className={classes.section1}>
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <Typography gutterBottom variant="h4">
                                        {product.name.toUpperCase() }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6">
                                        {this.state.selectedPrice && (`${new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: this.state.selectedPrice.currency,
                                        }).format((this.state.selectedPrice.unit_amount / 100).toFixed(2))} per ${this.state.selectedPrice.interval
                                            }`) }
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography color="textSecondary" variant="body2">{product.description }</Typography>
                        </div>
                        <Divider variant="middle" />
                        <div className={classes.section2}>
                            <Typography gutterBottom variant="body1">
                                Select recurring type
                            </Typography>
                            <div>
                                <div>{chips}</div>
                            </div>
                        </div>
                        <div className={classes.section3}>
                            {this.renderButton()}
                        </div>
                    </Box>
                </Grid>
            );
        }

        return (<div></div>);
    }
}


BoxProduct.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(BoxProduct);

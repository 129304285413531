import React, { Component } from "react";

import { auth } from "../../firebase";

import { Button } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";

import PropTypes from "prop-types";


import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    control: {
        padding: theme.spacing(2),
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1, 0, 2, 0),
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    section1: {
        margin: theme.spacing(3, 2),
    },
    section2: {
        margin: theme.spacing(2),
    },
    section3: {
        margin: theme.spacing(3, 1, 1, 2),
        overflow: "hidden",
    },
    wrapperBtn: {
        float: "left",
        position: 'relative',
    },
    progressBtn: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});


class BoxApiKey extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nCalls: 0,
            listApiKeys: [],
            openDialogDelete: false,
            selectedApiKey: null,
        };
    }


    getTotalCalls = async () => {
        const { user, openSnackbar, currentSubscription, setLoadingBtn, setCircBtn } = this.props;

        if (!user/*|| !currentSubscription*/) return false;

        const idToken_ = await auth.currentUser.getIdToken(false);

        if (!idToken_) {
            openSnackbar("An error occured. Try later or contact admin.");
            return false;
        }

        setLoadingBtn(true);
        setCircBtn("refreshCount", true);

        const responseUsage = await fetch(process.env.REACT_APP_ENDPOINT_WISEALGO + '/apikey/usage', {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + idToken_,
            }),
        });

        if (!responseUsage.ok) {
            const errorTxt = await responseUsage.json();
            openSnackbar("An error occured when trying to count usage: " + errorTxt);
        }
        else {
            let count = await responseUsage.json();
            count = parseInt(count);

            this.setTotalCalls(count);
        }

        setLoadingBtn(false);
        setCircBtn("refreshCount", false);
    }

    getApiKeys = async () => {
        const { user, openSnackbar, currentSubscription } = this.props;

        if (!user/*|| !currentSubscription*/) return false;

        const idToken_ = await auth.currentUser.getIdToken(false);

        if (!idToken_) {
            openSnackbar("An error occured. Try later or contact admin.");
            return false;
        }

        const responseList = await fetch(process.env.REACT_APP_ENDPOINT_WISEALGO + '/apikey/list', {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + idToken_,
            }),
        });

        if (!responseList.ok) {
            const errorTxt = await responseList.json();
            openSnackbar("An error occured when trying to count usage: " + errorTxt);
            return false;
        }

        const listApiKeys = await responseList.json();
        this.setApiKeys(listApiKeys);
    }

    createApiKey = async () => {
        const { user, openSnackbar, currentSubscription, setLoadingBtn, setCircBtn } = this.props;
        const { setCreateApiKeys } = this;

        if (!user/*|| !currentSubscription*/) return false;

        const idToken_ = await auth.currentUser.getIdToken(false);

        if (!idToken_) {
            openSnackbar("An error occured. Try later or contact admin.");
            return false;
        }

        setLoadingBtn(true);
        setCircBtn("createAPI", true);

        const responseKey = await fetch(process.env.REACT_APP_ENDPOINT_WISEALGO + '/apikey/generate', {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + idToken_,
            }),
        });

        if (!responseKey.ok) {
            const errorTxt = await responseKey.json();
            openSnackbar("An error occured when trying to count usage: " + errorTxt);
        }
        else {
            var apiKeyObj = await responseKey.json();

            setCreateApiKeys(apiKeyObj);
        }

        setLoadingBtn(false);
        setCircBtn("createAPI", false);
    }

    copyApiKey = (apiKeyId) => {
        navigator.clipboard.writeText(apiKeyId);

        this.props.openSnackbar("Copied.");
    }

    deleteApiKey = async (apiKeyId) => {
        const { user, openSnackbar, currentSubscription, setLoadingBtn, setCircBtn } = this.props;

        if (!user/*|| !currentSubscription*/) return false;

        const { setDeleteApiKeys } = this;
        const idToken_ = await auth.currentUser.getIdToken(false);

        if (!idToken_) {
            openSnackbar("An error occured. Try later or contact admin.");
            return false;
        }

        setLoadingBtn(true);
        setCircBtn("deleteAPI", true);

        const responseKey = await fetch(process.env.REACT_APP_ENDPOINT_WISEALGO + '/apikey/delete', {
            method: 'DELETE',
            headers: new Headers({
                'Authorization': 'Bearer ' + idToken_,
            }),
            body: JSON.stringify({
                APIKeyID: apiKeyId
            })
        });

        if (!responseKey.ok) {
            const errorTxt = await responseKey.json();
            openSnackbar("An error occured when trying to count usage: " + errorTxt);
        }
        else {
            setDeleteApiKeys(apiKeyId);
        }

        setLoadingBtn(false);
        setCircBtn("deleteAPI", false);
    }


    setTotalCalls = (nCalls) => {
        this.setState({ nCalls: nCalls });
    }

    setCreateApiKeys = (apiKey) => {
        const listApiKeys = this.state.listApiKeys;

        this.setState({
            listApiKeys: listApiKeys.concat([apiKey])
        });
    }

    setDeleteApiKeys = (apiKeyId) => {
        const listApiKeys = this.state.listApiKeys.filter(x => x.APIKeyID != apiKeyId);

        this.setState({
            listApiKeys: listApiKeys,
            openDialogDelete: false
        });
    }

    setApiKeys = (listApiKeys) => {
        this.setState({ listApiKeys: listApiKeys });
    }


    componentDidMount() {
        this.getTotalCalls();
        this.getApiKeys();
    }


    renderList = (apiKey) => {
        const { classes } = this.props;

        return (<ListItem key={apiKey.APIKeyID }>
            <ListItemText
                primary={apiKey.APIKeyValue }
            />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="copy" onClick={() => this.copyApiKey(apiKey.APIKeyValue)}>
                    <FileCopyIcon />
                </IconButton>
                <IconButton onClick={() => this.setState({ selectedApiKey: apiKey.APIKeyID }, () => this.setState({ openDialogDelete: true }) ) } edge="end" aria-label="delete">
                    <DeleteIcon color="error" />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>);
    }

    renderButton = () => {
        const { classes } = this.props;

        return (
            <div className={classes.wrapperBtn}>
                <Button disabled={this.props.loadingBtn} variant="contained" color="primary" onClick={async () => await this.createApiKey()}><AddIcon /> Generate</Button>
                {this.props.circBtn["createAPI"] && <CircularProgress size={24} className={classes.progressBtn} />}
            </div>
        );
    }

    render() {
        const { user, currentSubscription } = this.props;
        const { classes } = this.props;

        if (user) {
            const listApiKeys = [];

            this.state.listApiKeys?.forEach(apiKey => {
                listApiKeys.push(this.renderList(apiKey));
            });

            return (
                <>
                    <Grid xs={12} sm={8} key={1} item>
                        <Box className={classes.container} boxShadow={1}>
                            <div className={classes.section1}>
                                <Grid container alignItems="center">
                                    <Grid item xs>
                                        <Typography gutterBottom variant="h4">
                                            Manage API keys
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6">
                                            <div className={classes.wrapperBtn}>
                                                <Button disabled={this.props.loadingBtn} color="primary" onClick={async () => await this.getTotalCalls()}><CachedIcon /></Button>
                                                {this.props.circBtn["refreshCount"] && <CircularProgress size={24} className={classes.progressBtn} />}
                                            </div>
                                            {this.state.nCalls}/{currentSubscription && (100)}{!currentSubscription && (15)} calls
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={classes.section3}>
                                {this.renderButton()}
                            </div>
                            <div className={classes.section2}>
                                <List dense={true}>
                                    {listApiKeys}
                                </List>
                            </div>
                        </Box>
                    </Grid>
                    <Dialog open={this.state.openDialogDelete}>
                        <DialogTitle>Revoking</DialogTitle>
                        <Typography> Are you sure to revoke this API key? Action cannot be reverted. </Typography>
                        <DialogActions>
                            <Button disabled={this.props.loadingBtn} onClick={() => this.setState({ openDialogDelete: false })}>Cancel</Button>
                            <div className={classes.wrapperBtn}>
                                <Button disabled={this.props.loadingBtn} onClick={async () => await this.deleteApiKey(this.state.selectedApiKey) }>Confirm</Button>
                                {this.props.circBtn["deleteAPI"]  && <CircularProgress size={24} className={classes.progressBtn} />}
                            </div>
                        </DialogActions>
                    </Dialog>
                </>
            );
        }

        return (
            <>
                <Grid xs={12} sm={8} key={1} item>
                    <Box className={classes.container} boxShadow={1}>
                        <div className={classes.section1}>
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <Typography gutterBottom variant="h4">
                                        Manage API keys
                                        </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.section3}>
                            <Typography>
                                First, choose a plan and subscribe.
                            </Typography>
                        </div>
                    </Box>
                </Grid>
            </>
        );
    }
}


BoxApiKey.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,

    openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(BoxApiKey);

import React, { Component } from "react";
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import PropTypes from "prop-types";

import { Link as RouterLink } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  ButtonGroup,
  Button,
  IconButton,
  Divider,
  Menu,
  MenuItem,
  Link,
} from "@material-ui/core";

import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import UserAvatar from "../UserAvatar";
const drawerWidth = 240;

const styles = (theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
});


class Bar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        anchorEl: null,
      },
    };
  }

  openMenu = (event) => {
    const anchorEl = event.currentTarget;

    this.setState({
      menu: {
        anchorEl,
      },
    });
  };

  closeMenu = () => {
    this.setState({
      menu: {
        anchorEl: null,
      },
    });
  };

  render() {
    // Properties
    const { performingAction, user, userData, roles } = this.props;

    // Events
    const {
      onAboutClick,
      onSettingsClick,
      onSignOutClick,
      onSignUpClick,
      onSignInClick,
    } = this.props;

    const { menu } = this.state;

    const menuItems = [
      /*{
        name: "About",
        icon: "",
        onClick: onAboutClick,
      },
      {
        name: "Profile",
        icon: "",
        to: user ? `/user/${user.uid}` : null,
      },*/
      {
        name: "Settings",
        icon: <ListItemIcon><ExitToAppIcon fontSize="small" /></ListItemIcon>,
        onClick: onSettingsClick,
      },
      {
        name: "Sign out",
        icon: <ListItemIcon><SettingsIcon fontSize="small" /></ListItemIcon>,
        divide: true,
        onClick: onSignOutClick,
      },
    ];

    const drawerItems = [
      {
        name: "Connectors",
        icon: <ListItemIcon><HomeIcon fontSize="small" /></ListItemIcon>,
        to: "/",
      },
      {
        name: "Plans & API keys",
        icon: <ListItemIcon><CreditCardIcon fontSize="small" /></ListItemIcon>,
        to: "/plans",
      },
      {
        name: "Settings",
        icon: <ListItemIcon><SettingsIcon fontSize="small" /></ListItemIcon>,
        divide: true,
        onClick: onSettingsClick,
      },
    ];

    const { classes, theme } = this.props;

    // Functions
    const { getOpenMenu, setOpenMenu } = this.props;

    const handleDrawerOpen = () => {
        setOpenMenu(true);
    };

    const handleDrawerClose = () => {
        setOpenMenu(false);
    };

      return (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar color="primary" position="fixed" className={clsx(classes.appBar, {
          [classes.appBarShift]: getOpenMenu(),
        })}>
            <Toolbar>
                {user && (
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, getOpenMenu() && classes.hide)}
                >
                    <MenuIcon />
                </IconButton>
                )}

              <Box display="flex" flexGrow={1}>
                <Typography color="inherit" variant="h6">
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to="/"
                    underline="none"
                  >
                    {process.env.REACT_APP_TITLE}
                  </Link>
                </Typography>
              </Box>

              {user && (
                <>
                  {roles.includes("admin") && (
                    <Box mr={1}>
                      <Button
                        color="inherit"
                        component={RouterLink}
                        to="/admin"
                        variant="outlined"
                      >
                        Admin
                      </Button>
                    </Box>
                  )}

                  <IconButton
                    color="inherit"
                    disabled={performingAction}
                    onClick={this.openMenu}
                  >
                    <UserAvatar user={Object.assign(user, userData)} />
                  </IconButton>

                  <Menu
                    anchorEl={menu.anchorEl}
                    open={Boolean(menu.anchorEl)}
                    onClose={this.closeMenu}
                  >
                    {menuItems.map((menuItem, index) => {
                      if (
                        menuItem.hasOwnProperty("condition") &&
                        !menuItem.condition
                      ) {
                        return null;
                      }

                      let component = null;

                      if (menuItem.to) {
                        component = (
                          <MenuItem
                            key={index}
                            component={RouterLink}
                            to={menuItem.to}
                            onClick={this.closeMenu}
                          >
                            {menuItem.icon}
                            {menuItem.name}
                          </MenuItem>
                        );
                      } else {
                        component = (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              this.closeMenu();

                              menuItem.onClick();
                            }}
                          >
                            {menuItem.icon}
                            {menuItem.name}
                          </MenuItem>
                        );
                      }

                      if (menuItem.divide) {
                        return (
                          <span key={index}>
                            <Divider />

                            {component}
                          </span>
                        );
                      }

                      return component;
                    })}
                  </Menu>
                </>
              )}

              {!user && (
                <ButtonGroup
                  color="inherit"
                  disabled={performingAction}
                  variant="outlined"
                >
                  <Button onClick={onSignUpClick}>Sign up</Button>
                  <Button onClick={onSignInClick}>Sign in</Button>
                </ButtonGroup>
              )}
            </Toolbar>
          </AppBar>
          {user && (
          <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={getOpenMenu()}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    {drawerItems.map((item, index) => {
                      if (item.to) {
                          return <ListItem button component={RouterLink} key={item.name} to={item.to}>
                              {item.icon}
                              <ListItemText primary={item.name} />
                          </ListItem>
                      }
                      else {
                          return <ListItem button key={item.name} onClick={() => {
                              item.onClick();
                          }}>
                              {item.icon}
                              <ListItemText primary={item.name} />
                          </ListItem>
                      }
                    })}
                </List>
            </Drawer>
            )}
        </div>
    );
  }
}

Bar.defaultProps = {
  performingAction: false,
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Functions
  getOpenMenu: PropTypes.func.isRequired,
  setOpenMenu: PropTypes.func.isRequired,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,

  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Bar);
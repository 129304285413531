import React, { Component } from "react";
import clsx from 'clsx';

import PropTypes from "prop-types";

import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

import HomePage from "../HomePage";
import AdminPage from "../AdminPage";
import UserPage from "../UserPage";
import PlanPage from "../PlanPage";
import NotFoundPage from "../NotFoundPage";

const drawerWidth = 240;
const styles = (theme) => ({
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
    },
});

class Router extends Component {
  render() {
    // Properties
    const { user, roles, bar } = this.props;
    const { classes, theme } = this.props;

    // Functions
    const { openSnackbar, getOpenMenu } = this.props;

    return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        {bar}

        <main className={clsx(classes.content, {
            [classes.contentShift]: getOpenMenu(),
        })}>
            <div className={classes.drawerHeader} />

            <Switch>
                <Route path="/" exact>
                <HomePage 
                    setCircBtn={(p, v) => this.props.setCircBtn(p, v)}
                    circBtn={this.props.circBtn}
                    setLoadingBtn={(b) => this.props.setLoadingBtn(b)}
                    loadingBtn={this.props.loadingBtn}
                    user={user} openSnackbar={openSnackbar} />
                </Route>

                {/*<Route path="/admin">
                    {user && roles.includes("admin") ? (
                        <AdminPage />
                    ) : (
                            <Redirect to="/" />
                        )}
                </Route>*/}

                {/*<Route path="/user/:userId">
                    {user ? <UserPage /> : <Redirect to="/" />}
                </Route>*/}

                <Route path="/plans">
                    {user ? <PlanPage
                            setCircBtn={(p, v) => this.props.setCircBtn(p, v)}
                            circBtn={this.props.circBtn}
                            setLoadingBtn={(b) => this.props.setLoadingBtn(b)}
                            loadingBtn={this.props.loadingBtn}
                            openSnackbar={openSnackbar} user={user} /> : <Redirect to="/" />}
                </Route>

                <Route>
                    <NotFoundPage />
                </Route>
            </Switch>
        </main>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
  getOpenMenu: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(Router);

import React, { Component } from "react";

import EmptyState from "../EmptyState";
import { ReactComponent as InsertBlockIllustration } from "../../illustrations/insert-block.svg";

import { auth, firestore, firebaseFunction } from "../../firebase";
import { loadStripe } from '@stripe/stripe-js';

import Grid from '@material-ui/core/Grid';

import BoxProduct from "../BoxProduct";
import BoxCurrentPlan from "../BoxCurrentPlan";
import BoxApiKey from "../BoxApiKey";

import PropTypes from "prop-types";


import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    contentPlans: {
        margin: theme.spacing(1, 0, 0, 0),
    },
});


class PlanPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: null,
            prices: null,
            activeSubscription: null,
            stripeRole: null
        };
    }


    getProducts = async () => {
        const _docProducts = [];
        const _products = [];
        const _prices = [];

        await firestore.collection('products')
            .where('active', '==', true)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(async function (doc) {
                    const product = doc.data();
                    product.id = doc.id;

                    _products.push(product);
                    _docProducts.push(doc);
                });
            });

        await _docProducts.forEach(async docProd => {
            const priceSnap = await docProd.ref
                .collection('prices')
                .where('active', '==', true)
                .orderBy('unit_amount')
                .get();

            priceSnap.docs.forEach((doc) => {
                const priceId = doc.id;
                const priceData = doc.data();
                const newData = { ...priceData };
                newData.id = priceId;
                newData.productId = docProd.id;
                _prices.push(newData);
            });

            if (_docProducts.length == (_docProducts.indexOf(docProd) + 1))
                this.setProductsStates(_products, _prices);
        });
    }

    getActiveSubscriptions = async (currentUser) => {
        await firestore.collection('customers')
            .doc(currentUser)
            .collection('subscriptions')
            .where('status', 'in', ['trialing', 'active'])
            .onSnapshot(async (snapshot) => {
                if (snapshot.empty)
                    return;

                const subscription = await snapshot.docs[0].data().price.get();
                const newSubscription = { ...(subscription.data()) };
                newSubscription.id = subscription.id;
                this.setActiveSubscriptionStates(newSubscription);
            });
    }

    getStripeClaimRole = async () => {
        await auth.currentUser.getIdToken(true);
        const decodedToken = await auth.currentUser.getIdTokenResult();
        this.setStripeRoleState(decodedToken.claims.stripeRole);
    }


    setProductsStates = (products, prices) => {
        this.setState({ prices: prices });
        this.setState({ products: products });
    }

    setActiveSubscriptionStates = (_priceData) => {
        this.setState({ activeSubscription: _priceData });
    }

    setStripeRoleState = (_stripeRole) => {
        this.setState({ stripeRole: _stripeRole });
    }


    onSubscribeClick = async (priceId, userId) => {
        this.setState({ loadingBtn: true });

        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        const selectedPrice = {
            price: priceId,
            dynamic_tax_rates: [process.env.REACT_APP_STRIPE_TAX],
        };

        if (this.state.prices.find(x => x.id == selectedPrice.price)?.recurring?.usage_type !== 'metered')
            selectedPrice.quantity = 1;

        const docRef = await firestore
            .collection('customers')
            .doc(userId)
            .collection('checkout_sessions')
            .add({
                allow_promotion_codes: true,
                line_items: [selectedPrice],
                success_url: window.location.origin,
                cancel_url: window.location.origin,
                metadata: {
                    key: 'value',
                },
            });

        docRef.onSnapshot((snap) => {
            const { error, sessionId } = snap.data();

            if (error) {
                this.props.openSnackbar(`An error occured: ${error.message}`);
                document.querySelectorAll('button').forEach((b) => (b.disabled = false));
            }

            if (sessionId)
                stripe.redirectToCheckout({ sessionId });
        });

        this.setState({ loadingBtn: false });
    }

    onCheckoutClick = async () => {
        this.props.setLoadingBtn(true);
        this.props.setCircBtn("currentPlan", true);

        const functionRef = firebaseFunction.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
        const { data } = await functionRef({ returnUrl: window.location.origin });

        window.location.assign(data.url);

        this.props.setLoadingBtn(false);
        this.props.setCircBtn("currentPlan", false);
    }


    componentDidMount() {
        const { user } = this.props;

        this.getActiveSubscriptions(user.uid);
        this.getProducts();
        this.getStripeClaimRole();
    }


    renderBoxPlan = (product, prices) => {
        const { user } = this.props;

        if (this.state.activeSubscription == null)
            return <BoxProduct
                setCircBtn={(p, v) => this.props.setCircBtn(p, v)}
                circBtn={this.props.circBtn}
                setLoadingBtn={(b) => this.props.setLoadingBtn(b)}
                loadingBtn={this.props.loadingBtn}
                user={user} currentSubscription={this.state.activeSubscription} product={product} prices={prices} onClick={(i) => this.onSubscribeClick(i, user.uid)} />;
        else
            return <BoxProduct
                setCircBtn={(p, v) => this.props.setCircBtn(p, v)}
                circBtn={this.props.circBtn}
                setLoadingBtn={(b) => this.props.setLoadingBtn(b)}
                loadingBtn={this.props.loadingBtn}
                user={user} currentSubscription={this.state.activeSubscription} product={product} prices={prices} onClick={(i) => this.onCheckoutClick()} />;
    }

    renderManagePlan = () => {
        const { user } = this.props;

        return <BoxCurrentPlan
            setCircBtn={(p, v) => this.props.setCircBtn(p, v)}
            circBtn={this.props.circBtn}
            setLoadingBtn={(b) => this.props.setLoadingBtn(b)}
            loadingBtn={this.props.loadingBtn}
            user={user} activeSubscription={this.state.activeSubscription} stripeRole={this.state.stripeRole} onClick={() => this.onCheckoutClick()} />;
    }

    render() {
        const { user } = this.props;
        const { classes } = this.props;

        if (user) {
            const plansBox = [];

            this.state.products?.forEach(product => {
                const prices = this.state.prices
                    .filter(x => x.productId == product.id);

                plansBox.push(this.renderBoxPlan(product, prices));
            });

            return (
                <div>
                    <Grid className={classes.root} container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container justify="center" justify="center" spacing={2}>
                                {this.renderManagePlan()}
                            </Grid>
                        </Grid>
                        <Grid className={classes.contentPlans} item xs={12}>
                            <Grid container justify="center" justify="center" spacing={2}>
                                {plansBox}
                            </Grid>
                        </Grid>
                        <Grid className={classes.contentPlans} item xs={12}>
                            <Grid container justify="center" justify="center" spacing={2}>
                                {/*this.state.activeSubscription && */(<BoxApiKey
                                    setCircBtn={(p, v) => this.props.setCircBtn(p, v)}
                                    circBtn={this.props.circBtn}
                                    setLoadingBtn={(b) => this.props.setLoadingBtn(b)}
                                    loadingBtn={this.props.loadingBtn}
                                    openSnackbar={this.props.openSnackbar} user={user} currentSubscription={this.state.activeSubscription} />)}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            );
        }

        return (
            <EmptyState
            image={<InsertBlockIllustration />}
            title="WiseAlgo"
            description="Supercharge your projects or productions with smart algorithms."
            />
        );
    }
}


PlanPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,

    openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(PlanPage);
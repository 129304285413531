import React, { Component } from "react";

import PropTypes from "prop-types";

import { auth, firestore } from "../../firebase";

import authentication from "../../services/authentication";

import EmptyState from "../EmptyState";

import { ReactComponent as Login } from "../../illustrations/login.svg";

import JSONPretty from 'react-json-pretty';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GridOnIcon from '@material-ui/icons/GridOn';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Button } from "@material-ui/core";
import CachedIcon from '@material-ui/icons/Cached';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CircularProgress from '@material-ui/core/CircularProgress';


import { withStyles } from '@material-ui/core/styles';

const JSONPrettyMon = require('react-json-pretty/dist/monikai');

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginBottom: theme.spacing(4),
    },
    containerJson: {
        height: '500px',
        overflow: 'auto',
    },
    headerJsonResponse: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    progressBtn: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    jsonPretty: {
        '& pre': {
            margin: 0,
        }
    }
});


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valueTab: 0,
            lastJsonResponse: "",
        };
    }

    getLastJson = async () => {
        const { user, openSnackbar, setLoadingBtn, setCircBtn } = this.props;

        if (!user) return false;

        const idToken_ = await auth.currentUser.getIdToken(false);

        if (!idToken_) {
            openSnackbar("An error occured. Try later or contact admin.");
            return false;
        }

        setLoadingBtn(true);
        setCircBtn("refreshJSON", true);

        let lastJsonResponse = "";

        await firestore.collection('responses')
            .where('userId', '==', user.uid)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(async function (doc) {
                    const response = doc.data();
                    lastJsonResponse = response.jsonResponse;
                });
            });

        this.setState({ lastJsonResponse: lastJsonResponse });

        setLoadingBtn(false);
        setCircBtn("refreshJSON", false);
    };

  signInWithEmailLink = () => {
    const { user } = this.props;

    if (user) {
      return;
    }

    const emailLink = window.location.href;

    if (!emailLink) {
      return;
    }

    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");

      if (!emailAddress) {
        this.props.history.push("/");

        return;
      }

      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          this.props.openSnackbar(
            `Signed in as ${displayName || emailAddress}`
          );
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              this.props.openSnackbar(message);
              break;

            default:
              this.props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.props.history.push("/");
        });
    }
  };


    a11yProps = (index) => {
        return {
            id: `scrollable-force-tab-${index}`,
            'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }

    handleChange = (event, newValueTab) => {
        this.setState({ valueTab: newValueTab });
    };

    copyJSON = () => {
        navigator.clipboard.writeText(this.state.lastJsonResponse);

        this.props.openSnackbar("Copied.");
    }


  componentDidMount() {
    this.signInWithEmailLink();
    this.getLastJson();
  }


  render() {
    const { user } = this.props;
    const { classes } = this.props;

    if (user) {
      return (
          <Grid container justify="center" justify="center" spacing={2}>
              <Grid className={classes.root} xs={12} sm={8}>
                  <AppBar position="static" color="default">
                      <Tabs
                          value={this.state.valueTab}
                          onChange={this.handleChange}
                          variant="scrollable"
                          scrollButtons="on"
                          indicatorColor="primary"
                          textColor="primary"
                          aria-label="scrollable force tabs example"
                      >
                          <Tab label="Office add-in" icon={<GridOnIcon />} {...this.a11yProps(0)} />
                          <Tab label="Google sheet add-in" icon={<GridOnIcon />} {...this.a11yProps(1)} />
                          <Tab label="DHTMLX Gantt add-in" icon={<GridOnIcon />} {...this.a11yProps(2)} />
                          <Tab label="Other connectors" icon={<SettingsInputComponentIcon />} {...this.a11yProps(3)} />
                      </Tabs>
                  </AppBar>
                <TabPanel value={this.state.valueTab} index={0}>
                    <Button variant="contained" color="primary" href="https://www.google.com" target="_blank">
                        Link
                    </Button>
                </TabPanel>
                <TabPanel value={this.state.valueTab} index={1}>
                    <Button variant="contained" color="primary" href="https://www.google.com" target="_blank">
                        Link
                    </Button>
                </TabPanel>
                  <TabPanel value={this.state.valueTab} index={2}>
                    <Button variant="contained" color="primary" href="https://www.google.com" target="_blank">
                          Install via NPM
                    </Button>
                </TabPanel>
                <TabPanel value={this.state.valueTab} index={3}>
                    Coming soon...
              </TabPanel>
            </Grid>
              <Grid className={classes.root} xs={12} sm={8}>
                  <Grid className={classes.headerJsonResponse} container alignItems="center">
                      <Grid item xs>
                          <Typography gutterBottom variant="h4">
                              Last JSON response
                              </Typography>
                      </Grid>
                      <Grid item>
                          <Typography gutterBottom variant="h6">
                              <div>
                                  <Button onClick={() => this.copyJSON()}>
                                      <FileCopyIcon />
                                  </Button>
                                  <Button color="primary" disabled={this.props.loadingBtn} color="primary" onClick={async () => await this.getLastJson()}>
                                      <CachedIcon />
                                      {this.props.circBtn["refreshJSON"] && <CircularProgress size={24} className={classes.progressBtn} />}
                                  </Button>
                              </div>
                          </Typography>
                      </Grid>
                  </Grid>
                  <Grid className={classes.containerJson} xs={12}>
                      <JSONPretty id="json-pretty" className={classes.jsonPretty} themeClassName="jsonPrettyPre" theme={JSONPrettyMon} data={this.state.lastJsonResponse}></JSONPretty>
                  </Grid>
            </Grid>
        </Grid>
      );
    }

    return (
      <EmptyState
        image={<Login />}
        title="WiseAlgo"
        description="Supercharge your projects or productions with fast&smart algorithms."
      />
    );
  }
}


HomePage.propTypes = {
    user: PropTypes.object,
    openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(HomePage);
